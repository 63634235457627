<template>
    <div>
        <!-- <nav class="navbar" role="navigation" aria-label="main navigation" v-if="!['Premium'].includes(route)">
            <div class="navbar-brand">
                <div class="navbar-burger" @click="showMobile = !showMobile" :class="{ 'is-active': showMobile }">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>

            <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': showMobile }">
                <div class="navbar-start">
                    <router-link class="navbar-item link is-primary" to="/">
                        Arcane
                    </router-link>
                    <router-link class="navbar-item" to="/invite">
                        Invite
                    </router-link>
                    <router-link class="navbar-item" to="/commands">
                        Commands
                    </router-link>
                    <router-link class="navbar-item" to="/premium">
                        Premium
                    </router-link>
                    <router-link class="navbar-item" to="/status">
                        Status
                    </router-link>
                    <router-link class="navbar-item" to="/support">
                        Support
                    </router-link>
                    <div class="navbar-item has-dropdown is-hoverable">
                        <a class="navbar-link" @click="otherMobile = !otherMobile">
                            Other
                        </a>

                        <div class="navbar-dropdown has-text-black" v-if="otherMobile">
                          <router-link class="navbar-item" to="/calculator">
                            XP Calculator
                          </router-link>
                          <router-link class="navbar-item" to="/translations">Translations</router-link>
                          <router-link class="navbar-item" to="/suggestions">Suggestions</router-link>
                          <a class="navbar-item" href="https://applications.arcane.bot">Staff Applications</a>
                        </div>
                    </div>
                </div>

                <div class="navbar-end" v-if="user.authenticated">
                    <div class="navbar-item">
                        <div class="buttons">
                            <router-link :class="currentlyAuthenticating ? 'button is-primary is-loading' : 'button is-primary'" to="/dashboard/custom" v-if="[2,3,4].includes(user.premium)">
                                <strong>Custom Bot</strong>
                            </router-link>
                            <router-link :class="currentlyAuthenticating ? 'button is-primary is-loading' : 'button is-primary'" to="/staff" v-if="user.id === '295980401560649730'">
                                <strong>Owner</strong>
                            </router-link>
                            <router-link :class="currentlyAuthenticating ? 'button is-primary is-loading' : 'button is-primary'" to="/dashboard">
                                <strong>Manage Servers</strong>
                            </router-link>
                            <router-link :class="currentlyAuthenticating ? 'button is-primary is-loading' : 'button is-primary'" to="/me">
                                <strong>Settings</strong>
                            </router-link>
                            <router-link :class="currentlyAuthenticating ? 'button is-danger is-loading' : 'button is-danger'" to="/logout">
                                <strong>Logout {{user.username}}#{{user.discriminator}}</strong>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="navbar-end" v-else>
                    <div class="navbar-item">
                        <div class="buttons">
                            <router-link :class="currentlyAuthenticating ? 'button is-primary is-loading' : 'button is-primary'" to="login">
                                <strong>Login</strong>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </nav> -->

        <div style="background-color: transparent">
            <div class="nobg" style="background-color: transparent">
                <div class="navbar leave" style="background-color: transparent">
                    <div class="navbar-brand" style="background-color: transparent">
                        <router-link to="/">
                            <figure class="image logo">
                                <img class="is-rounded" src="@/assets/patreon.png" v-if="route === 'Premium'" draggable="false">
                                <img class="is-rounded" src="@/assets/arcane-static.png" v-else draggable="false">
                            </figure>
                        </router-link>


                        <div class="navbar-burger" @click="showMobile = !showMobile" :class="{ 'is-active': showMobile }">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>

                    <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': showMobile }">
                        <div class="navbar-start minimal">
                            <router-link class="navbar-item" to="/invite">
                                Add to Discord
                            </router-link>
                            <router-link class="navbar-item" to="/features/leveling">
                                Leveling
                            </router-link>
                            <router-link class="navbar-item" to="/features/counters">
                                Counters
                                <!-- <span class="tag is-primary is-size-7">New!</span> -->
                            </router-link>
                            <router-link class="navbar-item" to="/support">
                                Support
                            </router-link>
                            <div class="navbar-item">
                                <router-link class="premium" to="/premium">
                                    Premium
                                </router-link>
                            </div>
                            <!-- <router-link class="navbar-item" to="/status">
                                Status
                            </router-link> -->
                        </div>

                        <div class="navbar-end minimal" v-if="user.authenticated">
                            <!-- <div class="navbar-item"> -->
                                <!-- <div class="buttons">
                                    <router-link :class="currentlyAuthenticating ? 'button is-primary is-loading' : 'button is-primary'" to="/dashboard">
                                        <strong>Dashboard</strong>
                                    </router-link>
                                    <router-link :class="currentlyAuthenticating ? 'button is-danger is-loading' : 'button is-danger'" to="/logout">
                                        <strong>Logout {{user.username}}#{{user.discriminator}}</strong>
                                    </router-link>
                                </div> -->

                            <!-- <figure class="image is-32x32 avatar">
                                <img class="is-rounded" :src="'https://cdn.discordapp.com/avatars/' + user.id + '/' + user.avatar + '.png'" draggable="false">
                            </figure>
                            <div class="navbar-item no-space hover">
                                {{user.username}}#{{user.discriminator}}

                            </div> -->


                            <!-- </div> -->


                            <div class="buttons">
                                <!-- <router-link :class="currentlyAuthenticating ? 'button is-primary is-loading' : 'button is-primary'" to="/dashboard/custom" v-if="[2,3,4].includes(user.premium)">
                                    <strong>Custom Bot</strong>
                                </router-link>
                                <router-link :class="currentlyAuthenticating ? 'button is-primary is-loading' : 'button is-primary'" to="/staff" v-if="user.id === '295980401560649730'">
                                    <strong>Owner</strong>
                                </router-link> -->
                                <router-link :class="currentlyAuthenticating ? 'button is-primary is-loading' : 'button is-primary'" to="/dashboard">
                                    <strong>Manage Servers</strong>
                                </router-link>
                                <!-- <router-link :class="currentlyAuthenticating ? 'button is-primary is-loading' : 'button is-primary'" to="/me">
                                    <strong>Settings</strong>
                                </router-link> -->
                                <router-link :class="currentlyAuthenticating ? 'button is-danger is-loading' : 'button is-danger'" to="/logout">
                                    <strong>Logout {{user.username}}</strong>
                                </router-link>
                            </div>
                        </div>

                        <div class="navbar-end" v-else>
                            <div class="navbar-item">
                                <div class="buttons">
                                    <router-link :class="currentlyAuthenticating ? 'button is-primary is-loading' : 'button is-primary'" to="/login">
                                        <strong>Login</strong>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, } from 'vuex';

export default {
    name: 'Navbar',
    props: ['route'],
    data() {
        return {
            showMobile: false,
            otherMobile: false,
        }
    },
    computed: {
        ...mapState(['user', 'currentlyAuthenticating'])
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.premium-old {
    color: #ffb6c1;
}

.premium {
    background-clip: text; /* Clip the background to the text */
    -webkit-background-clip: text; /* For Safari/WebKit browsers */
    color: transparent;
    background-image: linear-gradient(to right, #ffb6c1, #C86DD7);

    border: 1.5px solid #C86DD7;
    border-radius: 5px;

    padding: 5px 10px 5px 10px;
}

.premium:hover, .premium:active, .premium:focus {
    color: white;
}

.buttons {
    padding: 0;
}

@media screen and (max-width: 750px) {
    .buttons {
        padding: 15px;
    }
}

.navbar-menu {
    background-color: transparent !important;
}

.navbar-item {
    transition: 150ms
}

.navbar-item:hover {
    /* background-color: #2a2d32; */
    background-color: transparent;
}

.navbar-item:active {
    /* background-color: #2a2d32; */
    background-color: transparent;
}
.navbar-dropdown {
    /* background-color: #2a2d32; */
    background-color: transparent;
}
.navbar-dropdown {
    /* background-color: #2a2d32;
    border-color: #393c42; */
    background-color: transparent;
}

.no-space {
    margin-left: 0px !important;
}

.nobg {
    margin-left: 25px;
    margin-top: 15px;
    margin-right: 25px;
    /* margin-bottom: 50px; */
}

.leave {
    background-color: transparent;
}

.avatar {
    /* padding-top: 17px; */
    padding-top: 12px;
    /* padding-right: -15px; */
}

.logo {
    margin-right: 15px;
    width: 50px;
    height: 50px;
}

.minimal .navbar-item {
    margin-left: 15px;
    font-weight: 500;
}


.minimal .navbar-item:hover {
    background-color: transparent;
}

.minimal .navbar-item:focus {
    background-color: transparent;
}


.hover {
    cursor: pointer;
    user-select: none;
}
</style>
