<template>
    <div class="bg">
        <div class="section nopadding">
            <div class='container'>
                <div class="columns">
                    <div class="column is-half">
                        <h1 class="arcane" style="font-weight: 600; font-size: 90px">Arcane</h1>
                        <p class="pls-use" style="font-size: 22px">
                            Levelup your Discord Server with the best Leveling and Multi-Purpose Discord Bot! Arcane will increase server activity while keeping the server squeaky clean. 
                            What are you waiting for? Join the other
                            <span class="highlight-stats fade-in">{{ stats.guilds }}</span> 
                            servers.
                        </p>
                        <br>
                        <router-link to='/invite' class="button is-primary">Add to Discord</router-link>
                        <AdSpot
                            ad-slot="home-mobile-links"
                            :mobileOnly="true"
                        ></AdSpot>
                    </div>
                    <div class="column is-desktop fade-in">
                        <img draggable="false" src="@/assets/arcane-static.png" class="logo" loading="lazy"/>
                    </div>
                </div>
                <!-- <div class="columns">
                    <div class="column has-text-centered">
                        <h1 class="title has-text-white">Create the BEST Discord server!</h1>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="wave">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250">
                <path :fill="theme === 'dark' ? '#2C2F33' : 'transparent'" transform="translate(0, -22)" d="M0,128L60,138.7C120,149,240,171,360,170.7C480,171,600,149,720,144C840,139,960,149,1080,165.3C1200,181,1320,203,1380,213.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
            </svg>
            <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 250" data-v-44df5d60="">
                <path fill="#2C2F33" d="M1920.63,26.94l-.13,305.7H.5V41.24c108.29,13,349.38,59.16,557.91,59.14,266.94,0,386.89-70.07,804.78-93.51C1688.37-11.37,1882.84,14.06,1920.63,26.94Z" data-v-44df5d60=""></path>
            </svg> -->
        </div>
        <div class="section" :style="theme === 'dark' ? 'background-color: #2C2F33' : 'background-color: transparent'">
            <div class="container">
                <div class="columns fade-in">

                    <div class="column is-half">
                        <br>
                        <img draggable="false" src="@/assets/promo/levelcard.png" class="showcase" loading="lazy"/>
                    </div>
                    <div class="column is-half">
                        <h1 class="showcase-title">Leveling</h1>
                        <h1 class="showcase">Arcane provides all servers with free role rewards and leaderboards. Configure and claim a custom link to your leaderboard. Configure roles to earn more xp, blacklist roles, and other configuration features are also included.</h1>
                        <router-link to="/features/leveling" class="button is-primary is-size-5" style="margin-top: 25px;">Explore leveling</router-link>
                    </div>
                </div>
                <div class="columns fade-in">
                    <div class="column is-half">
                        <h1 class="showcase-title">Voice Leveling</h1>
                        <h1 class="showcase">Enable voice leveling in your server and let Arcane start calculating voice activity. Voice leveling is powerful and able to filter out users who are afk, mute, deaf, or idling for xp. Our system is the BEST voice xp out there.</h1>
                    </div>
                    <div class="column is-half">
                        <br>
                        <img draggable="false" src="@/assets/promo/voice.png" class="showcase" loading="lazy" />
                    </div>
                </div>
                <AdSpot
                    ad-slot="homepage-ad-1"
                ></AdSpot>
                <div class="columns fade-in">
                    <div class="column is-half">
                        <br>
                        <img draggable="false" src="@/assets/features/counters/counters.png" class="showcase" loading="lazy" />
                    </div>
                    <div class="column is-half">
                        <h1 class="showcase-title">Counters</h1>
                        <h1 class="showcase">Quickly and easily setup channel counters to showcase your server's member count, bots, boosts, goals, and more..</h1>
                        <router-link to="/features/counters" class="button is-primary is-size-5" style="margin-top: 25px;">Explore counters</router-link>
                    </div>
                </div>
                <div class="columns fade-in">
                    <div class="column is-half">
                        <h1 class="showcase-title">Youtube Notifications</h1>
                        <h1 class="showcase">With Arcane you can easily setup notifications to be sent directly to your youtube audience through discord. Raise views and spark interest in your discord server.</h1>
                    </div>
                    <div class="column is-half">
                        <br>
                        <img draggable="false" src="@/assets/promo/youtube.png" class="showcase" loading="lazy" />
                    </div>
                </div>
                <div class="columns fade-in">
                    <div class="column is-half">
                        <br>
                        <img draggable="false" src="@/assets/promo/logging.png" class="showcase" loading="lazy" />
                    </div>
                    <div class="column is-half">
                        <h1 class="showcase-title">Advanced Logging</h1>
                        <h1 class="showcase">Configure Arcane to log members who leave and join your server in less than a minute. Log messages, channels, voice channels, and more with a few clicks.</h1>
                        <!-- <router-link to="/features/logging" class="button is-primary is-size-5" style="margin-top: 25px;">Explore logging</router-link> -->
                    </div>
                </div>
                <AdSpot
                    ad-slot="homepage-ad-2"
                ></AdSpot>
                <div class="columns fade-in">
                    <div class="column is-half">
                        <h1 class="showcase-title">
                            Moderation
                            <span class="tag is-primary">Discord AutoMod</span>
                        </h1>
                        <h1 class="showcase">Prevent spamming, swearing, mass emojis, mass capitals and more with Arcane's Auto Moderation which is built upon Discord AutoMod. Use the simple and intuitive moderation commands to warn, kick, mute, or ban troublemakers.</h1>
                        <router-link to="/features/moderation" class="button is-primary is-size-5" style="margin-top: 25px;">Explore moderation</router-link>
                    </div>
                    <div class="column is-half">
                        <br>
                        <img draggable="false" src="@/assets/promo/moderation.png" class="showcase" loading="lazy" />
                    </div>
                </div>
                <div class="columns fade-in">
                    <div class="column is-half">
                        <br>
                        <img draggable="false" src="@/assets/promo/custom-commands.png" class="showcase" loading="lazy" />
                    </div>
                    <div class="column is-half">
                        <h1 class="showcase-title">Custom Commands</h1>
                        <h1 class="showcase">With Arcane you can create custom commands to add roles, remove roles, provide information or more. The possibilities are endless...</h1>
                    </div>
                </div>
                <div class="columns fade-in">
                    <div class="column is-half">
                        <h1 class="showcase-title">Reaction Roles</h1>
                        <h1 class="showcase">Allow users to give themselves roles with a reaction and give your discord a modern feel. Setup reaction roles with a few clicks on the dashboard.</h1>
                    </div>
                    <div class="column is-half">
                        <br>
                        <img draggable="false" src="@/assets/promo/reactionrole.png" class="showcase" loading="lazy" />
                    </div>
                </div>
                <!-- <div class="columns is-centered">
                    <div class="column is-half has-text-centered">
                        <adsense
                            class="ad"
                            ad-client="ca-pub-8054338667382263"
                            ad-slot="8154473992"
                            ad-style="display:inline-block;height:90px;width:728px;"
                        >
                        </adsense>
                    </div>
                </div> -->
                <br>
                <br>
            </div>
        </div>


        <div class="wave upside-down">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250">
                <path :fill="theme === 'dark' ? '#2C2F33' : 'transparent'"  d="M0,192L30,181.3C60,171,120,149,180,133.3C240,117,300,107,360,90.7C420,75,480,53,540,48C600,43,660,53,720,69.3C780,85,840,107,900,101.3C960,96,1020,64,1080,80C1140,96,1200,160,1260,170.7C1320,181,1380,139,1410,117.3L1440,96L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path>
            </svg>
            <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 250" data-v-44df5d60="">
                <path fill="#2C2F33" d="M1920.63,26.94l-.13,305.7H.5V41.24c108.29,13,349.38,59.16,557.91,59.14,266.94,0,386.89-70.07,804.78-93.51C1688.37-11.37,1882.84,14.06,1920.63,26.94Z" data-v-44df5d60=""></path>
            </svg> -->
        </div>

        <div class="section" style="padding-bottom: 150px; padding-top: 0;">
            <div class="container fade-in">
                <h1 class="title has-text-centered has-text-white">Quick Links</h1>
                <div class="columns">
                    <div class="column is-half">
                        <div class="box">
                            <h1 class="title has-text-white is-size-4">Arcane Premium</h1>
                            <h1 class="subtitle has-text-grey-light is-size-6">Premium starts at $7. Premium helps us to pay for servers to run the bot on.</h1>
                            <router-link class="button is-primary" to="/premium">Learn more</router-link>                    
                        </div>
                    </div>
                    <div class="column is-half">
                        <div class="box">
                            <h1 class="title has-text-white is-size-4">Voting</h1>
                            <h1 class="subtitle has-text-grey-light is-size-6">Receive a 10% boost for voting! Voting allows Arcane to be advertised and found by new users.</h1>
                            <router-link class="button is-primary" to="/vote">Do it</router-link>                    
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <!-- <div class="column is-half">
                        <div class="box">
                            <h1 class="title has-text-white is-size-4">Support</h1>
                            <h1 class="subtitle has-text-grey-light is-size-6">Join our support server to receive help with the bot, be notified of updates, and chat.</h1>
                            <router-link class="button is-primary" to="/support">Join now</router-link>                    
                        </div>
                    </div> -->
                    <!-- <div class="column is-half">
                        <div class="box">
                            <h1 class="title has-text-white is-size-4">Review Arcane</h1>
                            <h1 class="subtitle has-text-grey-light is-size-6">Give us constructive feedback on how to improve Arcane and its features.</h1>
                            <router-link class="button is-primary" to="/review">Review now</router-link>                    
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PremiumPopup from '@/components/PremiumPopup.vue'

export default {
    name: 'home',
    components: {
        PremiumPopup,
    },
    data() {
        return {
            stats: {
                users: 'Loading',
                guilds: 'Loading'
            },
            theme: localStorage.getItem('theme') || 'dark'
        }
    },
    async created() {
        let data = await this.$axios.get(`/stats/simple`);
        if (data) {
            this.stats.guilds = data.data.guilds.toLocaleString();
            this.stats.users = data.data.users.toLocaleString();
        }
    },
    async mounted() {
        const handleScroll = (evt) => {
            // console.log(evt)
            for (var i = 0; i < fadeInElements.length; i++) {
                var elem = fadeInElements[i]
                if (isElemVisible(elem)) {
                    elem.style.opacity = '1'
                    elem.style.transform = 'scale(1)'
                    fadeInElements.splice(i, 1) // only allow it to run once
                }
            }
        }

        const isElemVisible = (el) => {
            var rect = el.getBoundingClientRect()
            var elemTop = rect.top + 100 // 200 = buffer
            var elemBottom = rect.bottom
            return elemTop < window.innerHeight && elemBottom >= 0
        }

        const fadeInElements = Array.from(document.getElementsByClassName('fade-in'));
        document.addEventListener('scroll', handleScroll);
        handleScroll();
        handleScroll();
        handleScroll();
    },
    methods: {
        plsStonks() {
        }
    },
}
</script>

<style scoped>
.fade-in {
    opacity: 0;
    transition: 1s all ease-out;
    transform: translateY(50%);

    margin-bottom: 150px;
}

@media only screen and (max-width: 1000px) {
    .fade-in {
        margin-bottom: 50px;
    }
}

.button {
    font-size: 20px;
}

.nopadding {
    padding-bottom: 0 !important;
}

.wave {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    transform: translateY(8px);
}


.upside-down {
    transform: rotate(180deg) translateY(8px)
}

.bg {
    
    user-select: none !important;
    background-image: url("~../assets/is_this_right.svg");
    
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
}

h1.arcane {
    color: #41B2B0;
    font-size: 55px;
    margin-bottom: 0px;
    background-color: transparent;
}
p.pls-use {
    color: #99aab5;
    font-size: 19px;
}

.highlight-stats {
    color: #41B2B0;
}
.button {
    margin-right: 5px;
    margin-bottom: 5px;
}

@keyframes float {
    0% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-5px);
    }
}
img.logo {
    animation: float 3s infinite ease-in-out;
    border-radius: 50% !important;
    border: none;
    box-shadow: 0px 3px 10px -3px black;
    width: 45%;
    margin-left: 25%;
    margin-top: 40px;
}
img.showcase {
    box-shadow: 0px 3px 10px -3px black;
    align-content: center;
    align-self: right;
    border-radius: 10px;
    transition: 0.4s ease-out;
}

img.showcase:hover {
    /* enlarge on hover */
    cursor: pointer;
    transform: scale(1.03);
	transition: all 0.4s ease-in-out;
	overflow: hidden;
}
h1.showcase {
    text-align: left;
    color: #99AAB5;
    font-size: 18px;
}
h1.showcase-title {
    color: #41B2B0;;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 40px;
    text-align: left; 
    margin-left: 0;
    padding-left: 0;
}

@media screen and (max-width: 960px) {
    img.logo {
        display: none !important;
    }
}

.box:hover {
    cursor: pointer;
    transform: scale(1.03);
	transition: all 0.4s ease-in-out;
	overflow: hidden;
}

.box {
	transition: 0.4s ease-out;
}

.section {
    user-select: none !important;
}
</style>

